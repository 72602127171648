import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReviewTable, { ReviewListResponse } from './ReviewTable';
import MovieSearchDialog, { Film } from './MovieSearchDialog';
import MessageDialog from './MessageDialog';
import DeleteDialog from './DeleteDialog';
import { ReviewRow, getReviewResponse, hasReview, deletePool } from './reviewPool';
import Alert from '@mui/material/Alert';
import { deleteCache } from './SearchResult';

interface Writer {
  member_id: number;
  name: string;
}

function makeOptions(writers: Writer[]) {
  return (
    <React.Fragment>
      {writers.map(writer => <option key={writer.member_id} value={writer.member_id}>{writer.name}</option>)}
    </React.Fragment>
  );
}

function makeResponse(writer: Writer | null, film: Film | null, checked: boolean, text: string, score: number): ReviewListResponse {
  const names: string[] = (writer !== null) ? [writer.name] : [];
  if (film !== null) {
    return {
      members: names.map(name => ({ name: name })),
      movies: [{
        image: `https://image.tmdb.org/t/p/w342${film.poster_path}`,
        date: film.release_date,
        title: film.title,
        reviews: [{
          score: score,
          text: text,
          how: checked ? 'screen' : 'video'
        }]
      }]
    };
  } else {
    return {
      members: names.map(name => ({ name: name })),
      movies: []
    };
  }
}

function canDelete(writer: Writer | null, film: Film | null): boolean {
  return writer !== null && film !== null && hasReview(film.id, writer.member_id);
}

function Edit() {
  const [searching, setSearching] = useState<boolean>(false);
  const [writers, setWriters] = useState<Writer[] | null>(null);
  const [writer, setWriter] = useState<Writer | null>(null);
  const [film, setFilm] = useState<Film | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [score, setScore] = useState<number>(1);
  const [password, setPassword] = useState<string>('');
  const [height, setHeight] = useState<number>(0);
  const [movie, setMovie] = useState<number | null>(null);
  const [posting, setPosting] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [deleting, setDeleting] = useState<boolean>(false);

  const navigate = useNavigate();

  const response = makeResponse(writer, film, checked, text, score);

  /** すでに投稿済みのレビューがあれば編集画面にセットする */
  const refresh = (member_id: number, reviews: ReviewRow[]) => {
    const review = reviews.find(review => review.member_id === member_id);
    if (review !== undefined) {
      setChecked(review.how === 'screen');
      setText(review.text);
      setScore(review.score);
    }
  };

  /** 検索ダイアログが閉じられたときに呼ばれる処理 */
  const onCloseSearchDialog = (film: Film | null) => {
    setSearching(false);
    if (film !== null) {
      setFilm(film);
      getReviewResponse(film.id).then(res => {
        setMovie(res.movie_id);
        if (writer !== null && res.movie_id !== null) {
          refresh(writer.member_id, res.reviews);
        }
      })
    }
  };

  /** 削除を試みる */
  const deleteMovie = async (word: string) => {
    const res = await fetch(`${process.env.REACT_APP_API}/review/${movie}/${writer!.member_id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: word })
    });
    if (!res.ok) {
      const obj = await res.json();
      throw new Error(obj.message);
    }
  };

  /** 削除ダイアログが閉じられたときに呼ばれる処理 */
  const onCloseDeleteDialog = async (word: string | null) => {
    setDeleting(false);
    if (word !== null) {
      try {
        await deleteMovie(word);
        deleteCache();
        deletePool();
        navigate('/cinema2');
      } catch (e: any) {
        setMessage(e.message);
      }
    }
  };

  /** 投稿者が変更されたときに呼ばれる処理 */
  const changeWriter = (event: ChangeEvent<HTMLSelectElement>) => {
    const member_id = Number(event.target.value);
    setWriter(writers?.find(writer => writer.member_id === member_id) ?? null);
    if (film !== null) {
      getReviewResponse(film.id).then(res => {
        if (res.movie_id !== null) {
          refresh(member_id, res.reviews);
        }
      });
    }
  };

  /** 作品の登録がなければ登録して ID を得る */
  const getMovieId = async (): Promise<number> => {
    if (movie !== null) {
      return movie;
    } else {
      const res = await fetch(`${process.env.REACT_APP_API}/movie/${film!.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          title: film!.title,
          date: film!.release_date,
          image: `https://image.tmdb.org/t/p/w342${film!.poster_path}`,
          password: password
        })
      });
      const obj = await res.json();
      if (!res.ok) {
        throw new Error(obj.message);
      }
      return obj.movie_id;
    }
  };

  /** レビューを投稿する */
  const post = async (movie_id: number): Promise<void> => {
    const res = await fetch(`${process.env.REACT_APP_API}/review/${movie_id}/${writer!.member_id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        score: Number(score),
        how: checked ? 'screen' : 'video',
        text: text,
        password: password
      })
    });
    if (!res.ok) {
      const obj = await res.json();
      throw new Error(obj.message);
    }
  }

  /** 投稿ボタンが押されたときに呼ばれる処理 */
  const go = async () => {
    setPosting(true);
    try {
      const movie_id = await getMovieId();
      await post(movie_id);
      deleteCache();
      deletePool();
      navigate('/cinema2');
    } catch (e: any) {
      setMessage(e.message);
    } finally {
      setPosting(false);
    }
  };

  /** 投稿ボタンが押せる条件 */
  const canPost = (writer !== null) && (film !== null) && (text.length > 0) && (password.length > 0) && (height < 353);

  /** 最初に一度だけ呼ばれる処理 */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/member`).then(res => res.json()).then(obj => { setWriters(obj.members); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** プレビューの高さが変わり得るタイミングで呼ばれる処理 */
  useEffect(() => {
    const elements = document.getElementsByClassName('table');
    const table = (elements.length > 0) ? elements[0] as HTMLTableElement : null;
    setHeight(table?.rows[1].offsetHeight ?? 0);
  }, [writer, film, text]);

  const buttonText = posting ? <div className="spinner-border spinner-border-sm"></div> : <React.Fragment>投稿</React.Fragment>;

  return (
    <React.Fragment>
      <div className="m-3">
        <div className="row">
          <div className="col"><h1>レビューを投稿</h1></div>
          <div className="col-auto">
            <Link to="/cinema2"><button className="btn btn-secondary">戻る</button></Link>
          </div>
        </div>
        <div className="m-3">
          <label htmlFor="writer" className="form-label fw-bold">投稿者</label>
          <select id="writer" className="form-select" defaultValue="0" onChange={changeWriter} style={{width: 'auto'}}>
            <option value="0">選択してください</option>
            {makeOptions(writers ?? [])}
          </select>
        </div>
        <div className="m-3">
          <label htmlFor="movie" className="form-label fw-bold">作品</label>
          <div id="movie" className="input-group mb-2" onClick={() => { setSearching(true); }}>
            <button className="btn btn-secondary"><i className="bi bi-search"></i></button>
            <input className="form-control" type="text" placeholder="検索してください" value={response.movies[0]?.title ?? ''} tabIndex={-1} readOnly />
          </div>
          <div className="form-check m-3">
            <input className="form-check-input" type="checkbox" id="checkbox" checked={checked} onChange={e => { setChecked(e.target.checked); }}/>
            <label className="form-check-label" htmlFor="checkbox">映画館で観た</label>
          </div>
        </div>
        <div className="m-3">
          <div className="row">
            <div className="col-auto align-self-end"><label htmlFor="impression" className="form-label fw-bold">本文</label></div>
            <div className="col mb-2"><Alert severity="error" className={(height < 353) ? 'invisible' : 'visible'}>行数オーバー</Alert></div>
          </div>
          <textarea id="impression" className="form-control mb-2" rows={3} value={text} onChange={e => { setText(e.target.value); }}></textarea>
        </div>
        <div className="m-3">
          <label htmlFor="score" className="form-label fw-bold">点数</label>
          <div id="score" className="row">
            <div className="col">
              <select className="form-select" value={score} onChange={e => { setScore(Number(e.target.value)); }} style={{width: 'auto'}}>
                <option value="10">10</option>
                <option value="9">9</option>
                <option value="8">8</option>
                <option value="7">7</option>
                <option value="6">6</option>
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1</option>
              </select>
            </div>
            <div className="col-auto">
              <button className={canDelete(writer, film) ? 'btn btn-danger' : 'invisible' } tabIndex={-1} onClick={() => { setDeleting(true); }}>削除</button>
            </div>
          </div>
        </div>
        <div className="m-3">
          <label className="form-label fw-bold">プレビュー</label>
          <ReviewTable obj={response}></ReviewTable>
        </div>
        <div className="m-3">
          <label htmlFor="password" className="form-label fw-bold">パスワード</label>
          <div id="password" className="row">
            <div className="col-auto">
              <input type="password" className="form-control" value={password} onChange={e => { setPassword(e.target.value); }} style={{width: '10rem'}} />
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" disabled={!canPost} style={{width: '4rem'}} onClick={go}>{buttonText}</button>
            </div>
          </div>
        </div>
      </div>
      <MovieSearchDialog open={searching} onClose={onCloseSearchDialog}></MovieSearchDialog>
      <MessageDialog text={message} onClose={() => { setMessage(''); }}></MessageDialog>
      <DeleteDialog open={deleting} onClose={onCloseDeleteDialog}></DeleteDialog>
    </React.Fragment>
  );
}

export default Edit;
