import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <React.Fragment>
      <div className="m-2"><Link to="archive">シネマ散歩 アーカイブ版</Link></div>
      <div className="m-2"><Link to="cinema2">シネマ散歩 次世代版</Link></div>
    </React.Fragment>
  );
}

export default App;
