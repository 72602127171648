import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MessageDialog from './MessageDialog';
import Alert from '@mui/material/Alert';
import MovieSearchDialog, { Film } from './MovieSearchDialog';
import { getMovieId } from './reviewPool';
import { deleteCache } from './SearchResult';

interface Best {
  movie_id: number;
  film: Partial<Film>;
}

interface Favorite {
  movie_id: number;
  title: string;
  tmdb_id: number;
}

interface Writer {
  member_id: number;
  name: string;
  favorites: Favorite[];
}

function makeOptions(writers: Writer[]) {
  return (
    <React.Fragment>
      {writers.map(writer => <option key={writer.member_id} value={writer.member_id}>{writer.name}</option>)}
    </React.Fragment>
  );
}

function getBestFromFavorite(favorite: Favorite): Best {
  return {
    movie_id: favorite.movie_id,
    film: {
      id: favorite.tmdb_id,
      title: favorite.title,
    },
  };
}

function register(best: Partial<Best>, password: string): Promise<number> {
  if (best.movie_id !== undefined) {
    return Promise.resolve(best.movie_id);
  } else {
    return getMovieId(best.film as Film, password);
  }
}

function take3(favorites: Favorite[]): Partial<Best>[] {
  return [0, 1, 2].map(i => (i < favorites.length) ? getBestFromFavorite(favorites[i]) : {});
}

function Rename() {
  const [writers, setWriters] = useState<Writer[] | null>(null);
  const [writer, setWriter] = useState<Writer | null>(null);
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [bests, setBests] = useState<Partial<Best>[]>([{}, {}, {}]);
  const [index, setIndex] = useState<number | null>(null);

  /** 投稿者が変更されたときに呼ばれる処理 */
  const changeWriter = (event: ChangeEvent<HTMLSelectElement>) => {
    const member_id = Number(event.target.value);
    const writer = writers?.find(writer => writer.member_id === member_id) ?? null;
    setWriter(writer);
    if (writer !== null) {
      setName(writer.name);
      setBests(take3(writer.favorites));
    }
  };

  const setFilm = (film: Film, i: number) => {
    setBests(bests.map((_, j) => (j !== i) ? bests[j] : { film: film }));
  }

  const onClose = (film: Film | null) => {
    if (film !== null && index !== null) {
      setFilm(film, index);
    }
    setIndex(null);
  };

  /** ベスト映画の1行分を作る関数 */
  const makeFavorite = (best: Partial<Best>, i: number) => {
    const clearOne = () => { setBests(bests.map((_, j) => (j !== i) ? bests[j] : {})); };
    return (
      <div className="row mb-2" key={i}>
        <div className="col">
          <div id="movie" className="input-group" onClick={() => { setIndex(i); }}>
            <button className="btn btn-secondary"><i className="bi bi-search"></i></button>
            <input className="form-control" type="text" placeholder="検索してください" value={best.film?.title ?? ''} tabIndex={-1} readOnly />
          </div>
        </div>
        <div className="col-auto">
          <button className="btn btn-link text-reset" onClick={clearOne}><i className="bi bi-x-lg"></i></button>
        </div>
      </div>
    );
  };

  const navigate = useNavigate();

  const post = async () => {
    const movie_ids = await Promise.all(bests.filter(best => best.film !== undefined).map(best => register(best, password)));
    const res = await fetch(`${process.env.REACT_APP_API}/member/${writer!.member_id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        movie_ids: movie_ids,
        password: password
      })
    });
    if (!res.ok) {
      const obj = await res.json();
      throw new Error(obj.message);
    }
  };

  /** 変更ボタンが押されたときに呼ばれる処理 */
  const go = async () => {
    try {
      await post();
      deleteCache();
      navigate('/cinema2');
    } catch (e: any) {
      setMessage(e.message);
    }
  };

  /** 変更ボタンが押せる条件 */
  const canPost = (writer !== null) && (0 < name.length) && (name.length <= 11) && (password.length > 0);

  /** 最初に一度だけ呼ばれる処理 */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/member`).then(res => res.json()).then(obj => { setWriters(obj.members); });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="m-3">
        <div className="row">
          <div className="col"><h1>プロフィールを変更</h1></div>
          <div className="col-auto">
            <Link to="/cinema2"><button className="btn btn-secondary">戻る</button></Link>
          </div>
        </div>
        <div className="m-3">
          <label htmlFor="before" className="form-label fw-bold">レビュアー</label>
          <select id="before" className="form-select" defaultValue="0" onChange={changeWriter} style={{width: 'auto'}}>
            <option value="0">選択してください</option>
            {makeOptions(writers ?? [])}
          </select>
        </div>
        <hr />
        <div className="m-3">
          <label htmlFor="after" className="form-label fw-bold">ペンネーム</label>
          <div className="row">
            <div className="col-auto align-self-center">
              <input id="after" className="form-control" type="text" value={name} onChange={e => { setName(e.target.value); }} style={{width: '16rem'}} />
            </div>
            <div className="col">
              <Alert severity="error" className={(name.length <= 11) ? 'invisible' : 'visible'}>文字数オーバー</Alert>
            </div>
          </div>
        </div>
        <div className="m-3">
          <label htmlFor="favorites" className="form-label fw-bold">ベスト映画</label>
          {bests.map(makeFavorite)}
        </div>
        <div className="m-3">
          <label htmlFor="password" className="form-label fw-bold">パスワード</label>
          <div id="password" className="row">
            <div className="col-auto">
              <input type="password" className="form-control" value={password} onChange={e => { setPassword(e.target.value); }} style={{width: '10rem'}} />
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" disabled={!canPost} onClick={go}>変更</button>
            </div>
          </div>
        </div>
      </div>
      <MessageDialog text={message} onClose={() => { setMessage(''); }}></MessageDialog>
      <MovieSearchDialog open={index !== null} onClose={onClose}></MovieSearchDialog>
    </React.Fragment>
  );
}

export default Rename;
