import React, { ChangeEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export interface Film {
  id: number;
  release_date: string;
  title: string;
  poster_path: string;
  overview: string;
}

function search(query: string): Promise<Film[]> {
  const params = new URLSearchParams({
    api_key: '9990491aa8a438cbbb47b567eccfc8ec',
    language: 'ja-JP',
    query: query,
    region: 'JP',
  });
  const url = `https://api.themoviedb.org/3/search/movie?${params}`;
  return fetch(url).then(res => res.json()).then(obj => obj.results);
}

function Dummy(props: { style: React.CSSProperties }) {
  return <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQI12NgYAAAAAMAASDVlMcAAAAASUVORK5CYII=" alt="dummy" style={props.style} />;
}

function makeImage(path: string) {
  if (path === null) {
    return <Dummy style={{width: '92px', height: '130px'}} />;
  } else {
    return <img src={`https://image.tmdb.org/t/p/w185${path}`} alt={path} style={{width: '92px'}} />;
  }
}

function makeCard(film: Film, onClose: (film: Film) => void) {
  return (
    <div className="card m-2 shadow-sm" key={film.id}>
      <div className="row flex-nowrap">
        <div className="col-auto align-self-center" style={{width: '92px'}}>
          {makeImage(film.poster_path)}
        </div>
        <div className="col">
          <div className="card-body small lh-sm">
            <div className="row">
              <p className="card-title fw-bold">{film.title}</p>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text text-muted">{film.release_date}</p>
              </div>
              <div className="col">
                <button className="btn btn-outline btn-sm stretched-link" onClick={() => { onClose(film); }}></button>
              </div>
            </div>
            <div className="row mt-2">
              <p className="card-text" style={{display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden'}}>
                {film.overview}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function compareFn(a: Film, b: Film): number {
  const d1 = a.release_date;
  const d2 = b.release_date;
  return (d1 < d2) ? 1 : (d1 > d2) ? -1 : 0;
}

function makePage(films: Film[] | null, onClose: (film: Film) => void) {
  if (films === null) {
    return (
      <div className="text-center mt-2">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {films.sort(compareFn).map(movie => makeCard(movie, onClose))}
      </React.Fragment>
    );
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MovieSearchDialogProps {
  open: boolean;
  onClose: (film: Film | null) => void;
}

let timer: NodeJS.Timeout | null = null;

function MovieSearchDialog(props: MovieSearchDialogProps) {
  const [text, setText] = useState<string>('');
  const [films, setFilms] = useState<Film[] | null>([]);

  const onClose = (film: Film | null) => {
    props.onClose(film);
    setText('');
    setFilms([]);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    const value = event.target.value;
    if (value === '') {
      timer = null;
      setFilms([]);
    } else {
      timer = setTimeout(() => {
        timer = null;
        search(value).then(setFilms);
      }, 1000);
      setFilms(null);
    }
    setText(value);
  };

  return (
    <Dialog open={props.open} onClose={() => { onClose(null); }} TransitionComponent={Transition} fullScreen>
      <div style={{ overflowX: 'hidden', overflowY: (films !== null) ? 'auto' : 'hidden' }}>
        <div className="row">
          <div className="col">
            <div className="input-group m-2">
              <span className="input-group-text"><i className="bi bi-search"></i></span>
              <input type="text" className="form-contol" value={text} onChange={onChange} autoFocus />
            </div>
          </div>
          <div className="col-auto">
            <button className="btn btn-secondary m-2" onClick={() => { onClose(null); }}><i className="bi bi-x-lg"></i></button>
          </div>
        </div>
        <div>
          {makePage(films, onClose)}
        </div>
      </div>
    </Dialog>
  );
}

export default MovieSearchDialog;
