import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfileDialog, { Member } from './ProfileDialog';

function makeLink(member: Member, onClick: () => void) {
  if (member.profile !== undefined) {
    return <Link to="." className="text-reset" onClick={onClick}><i className="bi bi-person text-primary"></i></Link>;
  } else {
    return <React.Fragment></React.Fragment>;
  }
}

function makeName(member: Member, i: number, onClick: () => void) {
  return (
    <th className="topmost" key={i}>
      {makeLink(member, onClick)}
      {member.name.substring(0, 11)}
    </th>
  );
}

export interface Review {
  score: number;
  text: string;
  how: string;
}

function makeReview(review: Review, i: number) {
  if (review === null) {
    return <td className="review" key={i}></td>;
  } else {
    const how = review.how.substring(0, 3);
    const score = review.score.toString().padStart(2, '0');
    const style = {
      backgroundImage: `url(${how + score + '.svg'})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '175px 280px',
    }
    return <td className="review" style={style} key={i}>{review.text}</td>;
  }
}

export interface Movie {
  image: string | null;
  date: string;
  title: string;
  reviews: Review[];
  tmdb?: number;
}

function makeImage(image: string | null, onClick: () => void, onBlur: () => void) {
  if (image === null) {
    return <React.Fragment></React.Fragment>;
  } else {
    return <img
      className="rounded"
      src={image}
      alt={image}
      style={{ width: '100px' }}
      tabIndex={0}
      onClick={onClick}
      onBlur={onBlur}
    />;
  }
}

function makeMovie(movie: Movie, i: number, clicked?: (movie: Movie) => void, cleared?: (movie: Movie) => void) {
  const onClick = () => { if (clicked !== undefined) { clicked(movie); } };
  const onBlur = () => { if (cleared !== undefined) { cleared(movie); } };

  return (
    <tr key={i}>
      <th className="table-light leftmost">
        {movie.date}<br />
        {movie.title}<br />
        {makeImage(movie.image, onClick, onBlur)}
      </th>
      {movie.reviews.map(makeReview)}
    </tr>
  );
}

export interface ReviewListResponse {
  members: Member[];
  movies: Movie[];
}

interface ReviewTableProps {
  obj: ReviewListResponse | null;
  header?: string;
  clicked?: (movie: Movie) => void;
  cleared?: (movie: Movie) => void;
}

function ReviewTable(props: ReviewTableProps) {
  const { obj } = props;
  const [member, setMember] = useState<Member | null>(null);

  if (obj === null) {
    return (
      <div className="text-center mt-2">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (obj.members.length === 0 || obj.movies.length === 0) {
    return <div className="m-2">条件に該当するレビューが存在しません</div>;
  } else {
    const n = obj.movies.length;
    return (
      <React.Fragment>
        <ProfileDialog member={member} onClose={() => { setMember(null); }}></ProfileDialog>
        <table className="table table-bordered" style={{ width: 'unset' }}>
          <thead className={`table-${props.header ?? 'primary'}`}>
            <tr>
              <th className="topmost" style={{ minWidth: '160px' }}>{(n > 1) ? `${n} movies` : ''}</th>
              {obj.members.map((member, i) => makeName(member, i, () => { setMember(member); }))}
            </tr>
          </thead>
          <tbody>
            {obj.movies.map((movie, i) => makeMovie(movie, i, props.clicked, props.cleared))}
          </tbody>
        </table>
      </React.Fragment>
    );
  };
}

export default ReviewTable;
