import { Film } from './MovieSearchDialog';

export interface ReviewRow {
  member_id: number;
  score: number;
  how: string;
  text: string;
}

interface ReviewDetailResponse {
  movie_id: number | null;
  reviews: ReviewRow[];
}

let cache: {[tmdb_id: number]: ReviewDetailResponse} = {};

export function getReviewResponse(tmdb_id: number): Promise<ReviewDetailResponse> {
  if (tmdb_id in cache) {
    return Promise.resolve(cache[tmdb_id]);
  } else {
    return fetch(`${process.env.REACT_APP_API}/review/${tmdb_id}`).then(res => res.json()).then(obj => {
      cache[tmdb_id] = obj;
      return obj;
    });
  }
}

export function hasReview(tmdb_id: number, member_id: number): boolean {
  if (tmdb_id in cache) {
    const reviews = cache[tmdb_id].reviews ?? [];
    return reviews.find(review => review.member_id === member_id) !== undefined;
  } else {
    return false;
  }
}

export function deletePool() {
  cache = {};
}

// Edit.tsx のローカル関数 getMovieId はこれに置き換え可能だと思うが今はやめておく
export async function getMovieId(film: Film, password: string): Promise<number> {
  const tmdb_id = film.id;
  await getReviewResponse(tmdb_id);
  const movie_id = cache[tmdb_id].movie_id;
  if (movie_id !== null) {
    return movie_id;
  } else {
    const res = await fetch(`${process.env.REACT_APP_API}/movie/${film.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        title: film.title,
        date: film.release_date,
        image: `https://image.tmdb.org/t/p/w342${film.poster_path}`,
        password: password
      })
    });
    const obj = await res.json();
    if (!res.ok) {
      throw new Error(obj.message);
    }
    return obj.movie_id;
  }
}
