import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Cinema1, Cinema2 } from './Cinema';
import Edit from './Edit';
import Ranking from './Ranking';
import Rename from './Rename';
import Rework from './Rework';
import Sort from './Sort';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/archive" element={<Cinema1 />} />
        <Route path="/cinema2" element={<Cinema2 />} />
        <Route path="/eigakyo/2023" element={<Ranking />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/rename" element={<Rename />} />
        <Route path="/rework" element={<Rework />} />
        <Route path="/sort/:key/:member" element={<Sort />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
