import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReviewTable, { ReviewListResponse, Movie } from './ReviewTable';
import './App.css';

let cache: {[id: string]: ReviewListResponse} = {};

interface SearchResultProps {
  id: string;
  params: URLSearchParams;
  header?: string;
}

function ask(props: SearchResultProps): Promise<ReviewListResponse> {
  const { id, params } = props;
  if (id in cache) {
    return Promise.resolve(cache[id]);
  } else {
    return fetch(`${process.env.REACT_APP_API}/review?` + params).then(response => response.json());
  }
}

export function deleteCache() {
  cache = {};
}

const count: {[tmdb: number]: number} = {};

function SearchResult(props: SearchResultProps) {
  const [obj, setObj] = React.useState<ReviewListResponse | null>(null);

  const navigate = useNavigate();

  const clicked = (movie: Movie) => {
    const i = movie.tmdb ?? null;
    if (i !== null) {
      if (i in count) {
        count[i] += 1;
      } else {
        count[i] = 1;
      }

      if (count[i] >= 4) {
        count[i] = 0;
        navigate('../rework', {state: movie});
      }
    }
  };

  const cleared = (movie: Movie) => {
    const i = movie.tmdb ?? null;
    if (i !== null) { count[i] = 0; }
  };

  React.useEffect(() => {
    if (obj === null) {
      ask(props).then(obj => {
        cache[props.id] = obj;
        setObj(obj);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ReviewTable obj={obj} header={props.header} clicked={clicked} cleared={cleared}></ReviewTable>;
}

export default SearchResult;
