import { useState } from 'react';
import Dialog from '@mui/material/Dialog';

function DeleteDialog(props: { open: boolean, onClose: (password: string | null) => void }) {
  const [password, setPassword] = useState<string>('');

  return (
    <Dialog open={props.open} onClose={() => { props.onClose(null); }}>
      <div className="m-3">
        <label htmlFor="password" className="form-label fw-bold">パスワード</label>
        <div id="password" className="row">
          <div className="col">
            <input type="password" className="form-control" value={password} onChange={e => { setPassword(e.target.value); }} style={{width: '10rem'}} />
          </div>
          <div className="col">
            <button className="btn btn-danger" onClick={() => { props.onClose(password); }} disabled={password.length === 0}>削除</button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteDialog;
