import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import SearchResult from './SearchResult';
import './App.css';

interface Option {
  id: string;
  label: string;
  params: URLSearchParams;
};

function makeRadio(option: Option, checked: (value: string) => boolean, onChange: React.ChangeEventHandler<HTMLInputElement>) {
  const { id, label } = option;
  return (
    <div className="form-check form-check-inline" key={id}>
      <input className="form-check-input" type="radio" name="radio" id={id} value={id} checked={checked(id)} onChange={onChange} />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  );
}

const options1: Option[] = [
  { id: 'good1', label: 'おすすめ', params: new URLSearchParams({ gen: '1', avg_gt: '8.5', head_gte: '2' }) },
  { id: 'ten1', label: '満点あり', params: new URLSearchParams({ gen: '1', max_gte: '10' }) },
  { id: 'bad1', label: 'ガッカリ', params: new URLSearchParams({ gen: '1', avg_lt: '3.0' }) },
  { id: 'allstar1', label: '揃い踏み', params: new URLSearchParams({ gen: '1', head_gte: '4' }) },
  { id: '2002', label: '2002', params: new URLSearchParams({ gen: '1', date_gte: '2002-01-01' }) },
  { id: '2001', label: '2001', params: new URLSearchParams({ gen: '1', date_gte: '2001-01-01', date_lt: '2002-01-01' }) },
  { id: '2000', label: '2000', params: new URLSearchParams({ gen: '1', date_lt: '2001-01-01' }) },
];

export function Cinema1() {
  const [id, setId] = React.useState<string>('good1');

  const isChecked = (value: string) => value === id;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => { setId(e.target.value); };

  const params = options1.find(option => option.id === id)?.params ?? new URLSearchParams();

  return (
    <React.Fragment>
      <div className="row">
        <div className="col align-self-center text-nowrap m-2">{options1.map(option => makeRadio(option, isChecked, onChange))}</div>
      </div>
      <SearchResult key={id} id={id} params={params} header="dark"></SearchResult>
    </React.Fragment>
  );
}

const options2: Option[] = [
  { id: 'fresh', label: '新着', params: new URLSearchParams({ gen: '2', within: '7' }) },
  { id: '2024', label: '今年', params: new URLSearchParams({ gen: '2', date_gte: '2024-01-01' }) },
  { id: '2023b', label: '23下', params: new URLSearchParams({ gen: '2', date_gte: '2023-07-01', date_lt: '2024-01-01' }) },
  { id: '2023a', label: '23上', params: new URLSearchParams({ gen: '2', date_gte: '2023-01-01', date_lt: '2023-07-01' }) },
  { id: 'old', label: '過去', params: new URLSearchParams({ gen: '2', date_lt: '2023-01-01' }) },
  { id: 'good2', label: 'おすすめ', params: new URLSearchParams({ gen: '2', avg_gt: '8.5', head_gte: '2' }) },
  { id: 'ten2', label: '満点あり', params: new URLSearchParams({ gen: '2', max_gte: '10' }) },
  { id: 'bad2', label: 'ガッカリ', params: new URLSearchParams({ gen: '2', avg_lt: '3.0' }) },
  { id: 'allstar2', label: '揃い踏み', params: new URLSearchParams({ gen: '2', head_gte: '5' }) },
];

export function Cinema2() {
  const [id, setId] = React.useState<string>('fresh');

  const isChecked = (value: string) => value === id;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => { setId(e.target.value); };

  const params = options2.find(option => option.id === id)?.params ?? new URLSearchParams();

  return (
    <React.Fragment>
      <div className="row flex-nowrap w-100">
        <div className="col-auto m-2">
          <div className="dropdown">
            <button className="btn btn-success" type="button" id="dropdown1" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="bi bi-pencil"></i>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdown1">
              <li><Link className="dropdown-item" to="/edit">レビュー投稿</Link></li>
              <li><Link className="dropdown-item" to="/rename">プロフィール変更</Link></li>
            </ul>
          </div>
        </div>
        <div className="col align-self-center text-nowrap m-2">{options2.map(option => makeRadio(option, isChecked, onChange))}</div>
      </div>
      <SearchResult key={id} id={id} params={params}></SearchResult>
    </React.Fragment>
  );
}
