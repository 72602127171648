import Dialog from '@mui/material/Dialog';
import React from 'react';

interface Favorite {
  title: string;
  image: string;
}

interface Profile {
  image: string;
  count: number;
  average: number;
  favorites: Favorite[];
}

export interface Member {
  name: string;
  profile?: Profile;
}

const EMPTY = <React.Fragment></React.Fragment>;

function makeSpiciness(profile: Profile) {
  if (profile.count < 10) {
    return EMPTY;
  } else {
    const avg = Math.floor(profile.average * 100) / 100;
    const spiciness = (avg < 6.0) ? '激辛'
                    : (avg < 6.5) ? '辛口'
                    : (avg < 7.0) ? '普通'
                    : (avg < 7.5) ? '甘口'
                                  : '激甘';
    return <div>スコア傾向：{spiciness}（平均 {avg.toFixed(2)}）</div>;
  }
}

function makeProfile(profile: Profile) {
  return (
    <div className="m-3">
      <div>レビュー数：{profile.count}</div>
      {makeSpiciness(profile)}
    </div>
  );
}

function makeFavorite(favorite: Favorite) {
  return (
    <div className="row m-1" key={favorite.title}>
      <div className="col-2 text-center align-self-center">
        <img className="rounded" src={favorite.image} style={{height: '64px'}} alt={favorite.title} />
      </div>
      <div className="col fs-5 align-self-center ms-1">{favorite.title}</div>
    </div>
  );
}

function makeFavorites(profile: Profile) {
  if (profile.favorites.length > 0) {
    return (
      <React.Fragment>
        <hr />
        <h4>ベスト映画</h4>
        {profile.favorites.map(makeFavorite)}
      </React.Fragment>
    );
  } else {
    return EMPTY;
  }
}

function makeAvatar(path: string) {
  return (
    <div className="col-auto align-self-center">
      <img src={path} style={{height: '128px'}} alt={path} />
    </div>
  );
}

function makeDialog(member: Member | null) {
  if (member !== null) {
    const profile = member.profile ?? null;
    const path = profile?.image ?? 'avatar.png';
    if (profile !== null) {
      return (
        <div className="m-3" style={{overflowX: 'hidden'}}>
          <div className="row">
            {makeAvatar(path)}
            <div className="col">
              <h3>{member.name}</h3>
              {(profile !== null) ? makeProfile(profile) : EMPTY}
            </div>
          </div>
          {(profile !== null) ? makeFavorites(profile) : EMPTY}
        </div>
      );
    }
  } else {
    return EMPTY;
  }
}

function ProfileDialog(props: { member: Member | null, onClose: () => void }) {
  return (
    <Dialog open={props.member !== null} onClose={props.onClose} fullWidth={true} PaperProps={{ sx: { position: "fixed", top: 40 } }}>
      {makeDialog(props.member)}
    </Dialog>
  );
}

export default ProfileDialog;
