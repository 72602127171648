import Dialog from '@mui/material/Dialog';

function MessageDialog(props: { text: string, onClose: () => void }) {
  return (
    <Dialog open={props.text !== ''} onClose={props.onClose}>
      <div className="row m-2">
        <div className="col align-self-center">
          <i className="bi bi-exclamation-triangle"></i> {props.text}
        </div>
        <div className="col-auto">
          <button className="btn btn-secondary btn-sm" onClick={props.onClose}>OK</button>
        </div>
      </div>
    </Dialog>
  );
}

export default MessageDialog;
