import React, { useState, useEffect } from 'react';

interface Item {
  date: string;
  image: string;
  movie: number;
  order: number | null;
  score: number;
  text: string;
  title: string;
  countries: string | null;
}

interface Country {
  name: string;
  iso_3166_1: string;
}

async function loadItems(): Promise<Item[]> {
  const res = await fetch(`${process.env.REACT_APP_API}/ranking/2023/1`);
  return await res.json();
}

function makeRank(i: number) {
  const style = { fontFamily: 'Kosugi Maru '};
  if (i <= 3) {
    Object.assign(style, {
      backgroundImage: `url(/medal${i}.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '72px 72px',
      backgroundPosition: 'top 8px center',
      padding: '20px',
      height: '80px',
    });
  } else if (i <= 10) {
    Object.assign(style, {
      backgroundImage: `url(/laurel.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '80px',
      backgroundPosition: 'top 4px center',
      padding: '20px',
      height: '80px',
    });
  }
  return <h3 style={style}>{i}位</h3>;
}

function getFlagFromCode(code: string): string {
  const points = code.toUpperCase().split('').map(c => c.charCodeAt(0) - 'A'.charCodeAt(0) + 0x1F1E6);
  return String.fromCodePoint(...points);
}

function makeFromItem(item: Item, i: number) {
  const orders = ['order-md-1', 'order-md-2'];
  const countries: Country[] = JSON.parse(item.countries ?? '[]');
  return (
    <div className="container mb-5" key={item.movie}>
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          {makeRank(i + 1)}
          <h2 style={{ fontFamily: 'Zen Antique' }}>{item.title}</h2>
          <div className="mb-2">{countries.map(country => getFlagFromCode(country.iso_3166_1)).join(' ')}</div>
        </div>
        <div className={`col-12 col-md-6 text-center ${orders[i % 2]}`}>
          <img src={item.image} className="img-fluid rounded mb-3" alt={item.title} style={{ width: '50%' }}/>
        </div>
        <div className={`col-12 col-md-6 align-self-center ${orders[(i + 1) % 2]}`} style={{ fontFamily: 'serif' }}>
          <p>{item.text}</p>
        </div>
      </div>
    </div>
  );
}

function Ranking() {
  const [items, setItems] = useState<Item[]>([]);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    loadItems().then(setItems);
  }, []);

  useEffect(() => {
    if (message !== '') {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  const text = (items.length > 0) ? `フルーノが決める！ 怒涛の2023年ベスト${items.length}` : '';
  return (
    <React.Fragment>
      <h1 className="ms-2 mt-2" style={{ fontFamily: 'Kaisei Tokumin' }}>映画<span style={{ color: 'magenta' }}>狂</span>の部屋</h1>
      <div className="ms-2 text-muted"><small>{text}</small></div>
      {items.map((item, i) => makeFromItem(item, i))}
    </React.Fragment>
  );
}

export default Ranking;
