import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MessageDialog from './MessageDialog';
import { Movie } from './ReviewTable';
import { deleteCache } from './SearchResult';

const regexp = new RegExp('https://image.tmdb.org/t/p/w342/([0-9a-zA-Z]{26,27}).jpg');

function getKey(image: string): string {
  const match = image.match(regexp);
  return (match !== null && match.length > 1) ? match[1] : '';
}

const re1 = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
const re2 = new RegExp('^[0-9a-zA-Z]{26,27}$');

function makeImage(url: string | null) {
  if (url !== null) {
    return <img src={url} alt="Not Found" height="200" />;
  } else {
    return <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" width="0" height="200" alt="" />;
  }
}

function Rework() {
  const location = useLocation();
  const movie: Movie = location.state;
  const [date, setDate] = useState<string>(movie.date);
  const [title, setTitle] = useState<string>(movie.title);
  const [key, setKey] = useState<string>(getKey(movie.image ?? ''));
  const [password, setPassword] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const navigate = useNavigate();

  const validDate = re1.test(date);
  const validTitle = title.length > 0;
  const validKey = re2.test(key);
  const validPassword = password.length > 0;
  const canPost = validDate && validTitle && validKey && validPassword;
  const url = validKey ? `https://image.tmdb.org/t/p/w342/${key}.jpg` : null;

  const post = async () => {
    const res = await fetch(`${process.env.REACT_APP_API}/movie/${movie.tmdb}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        title: title,
        date: date,
        image: url,
        password: password
      })
    });
    if (!res.ok) {
      const obj = await res.json();
      throw new Error(obj.message);
    }
  };

  const go = async () => {
    try {
      await post();
      deleteCache();
      navigate('/cinema2');
    } catch (e: any) {
      setMessage(e.message);
    }
  };

  return (
    <React.Fragment>
      <div className="m-3">
        <div className="row">
          <div className="col"><h1>作品情報を修正</h1></div>
          <div className="col-auto">
            <Link to="/cinema2"><button className="btn btn-secondary">戻る</button></Link>
          </div>
          <div className="m-2">
            <a href={`https://www.themoviedb.org/movie/${movie.tmdb}/images/posters?language=ja`} target="_blank" rel="noreferrer">TMDb</a>
          </div>
          <div className="m-2">
            <label className="form-label fw-bold">公開日</label>
            <input className="form-control" type="text" value={date} onChange={e => { setDate(e.target.value); }} style={{width: '8rem'}} />
          </div>
          <div className="m-2">
            <label className="form-label fw-bold">タイトル</label>
            <input className="form-control" type="text" value={title} onChange={e => { setTitle(e.target.value); }} />
          </div>
          <div className="m-2">
            <label className="form-label fw-bold">画像 URL</label>
            <div className="input-group" style={{fontFamily: 'monospace'}}>
              <span className="input-group-text">https://image.tmdb.org/t/p/w342/</span>
              <input className="form-control" type="text" value={key} onChange={e => { setKey(e.target.value); }} />
              <span className="input-group-text">.jpg</span>
            </div>
          </div>
          <div className="m-2">
            <label className="form-label fw-bold">画像確認</label>
            <div>{makeImage(url)}</div>
          </div>
          <div className="m-2">
            <label htmlFor="password" className="form-label fw-bold">パスワード</label>
            <div id="password" className="row">
              <div className="col-auto">
                <input type="password" className="form-control" value={password} onChange={e => { setPassword(e.target.value); }} style={{width: '10rem'}} />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" disabled={!canPost} onClick={go}>修正</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageDialog text={message} onClose={() => { setMessage(''); }}></MessageDialog>
    </React.Fragment>
  );
}

export default Rework;
