import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

declare const Sortable: any;

interface Item {
  date: string;
  image: string;
  movie: number;
  order: number | null;
  score: number;
  text: string;
  title: string;
  countries: string | null;
}

async function loadItems(key: string, member: string): Promise<Item[]> {
  const res = await fetch(`${process.env.REACT_APP_API}/ranking/${key}/${member}`);
  return await res.json();
}

function makeCardFromItem(item: Item, i: number) {
  const score = item.score.toString();
  return (
    <li className="list-group-item" key={item.movie} style={{ border: 'none', maxWidth: '800px' }}>
      <div className="card shadow-sm">
        <div className="d-flex">
          <div className="align-self-center text-end" style={{ minWidth: '3em' }}>{i + 1}位</div>
          <div className="align-self-center text-center" style={{ minWidth: '80px' }}>
            <img src={item.image} style={{ width: '50px' }} alt={item.title} />
          </div>
          <div className="align-self-center flex-fill">
            <div className="text-muted">{item.date}</div>
            <div className="fw-bold">{item.title}</div>
          </div>
          <div className="align-self-center text-center" style={{ minWidth: '60px' }}>
            <img src={`/scr${score.toString().padStart(2, '0')}.svg`} style={{ height: '80px' }} alt={score} />
          </div>
        </div>
      </div>
    </li>
  );
}

function Sort() {
  const { key, member } = useParams();
  const [items, setItems] = useState<Item[]>([]);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    const el = document.getElementById('sortable-list');
    const sortable = (el === null) ? null : Sortable.create(el, {
      onEnd: (event: any) => {
        const { oldIndex, newIndex } = event;
        if (oldIndex !== newIndex) {
          setItems(items => {
            const items_ = Array.from(items);
            const [item] = items_.splice(oldIndex, 1);
            items_.splice(newIndex, 0, item);
            return items_;
          });
        }
      }
    });
    return () => {
      sortable?.destroy();
    };
  }, []);

  useEffect(() => {
    if (key !== undefined && member !== undefined) {
      loadItems(key, member).then(setItems);
    }
  }, [key, member]);

  useEffect(() => {
    if (message !== '') {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  const post = async () => {
    const res = await fetch(`${process.env.REACT_APP_API}/ranking/${key}/${member}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        movie_ids: items.map(item => item.movie),
      })
    });
    const message = res.ok ? '保存しました' : '保存に失敗しました';
    setMessage(message);
  };

  return (
    <React.Fragment>
      <div className="sticky-top bg-white shadow">
        <button className="btn btn-primary m-2" onClick={() => { post(); }}>保存</button>
        <b>{message}</b>
      </div>
      <ul id="sortable-list" className="list-group" style={{ paddingRight: '48px' }}>
        {items.map((item, i) => makeCardFromItem(item, i))}
      </ul>
    </React.Fragment>
  );
}

export default Sort;
